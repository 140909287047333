import { useState, useEffect } from "react";
import { ethers } from "ethers";
import CockyRoaches from "./artifacts/contracts/CockyRoaches721A.sol/CockyRoaches721A.json";
import "./App.css";
const CRAdress = "0x900551aDC4634CC755C693A8eE96D27aa85df3Df";
function App() {
  const [error, setError] = useState("");
  const [owner, setOwner] = useState("");
  const [data, setData] = useState({});
  const [account, setAccount] = useState([]);
  useEffect(() => {
    getAccounts();
  }, []);
  useEffect(() => {
    fetchData();
  }, [account]);
  async function getAccounts() {
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccount(accounts);
      console.log(accounts[0]);
    }
  }
  async function fetchData() {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        CRAdress,
        CockyRoaches.abi,
        provider
      );
      try {
        const isWhitelisted = await contract.isUserWhitelisted(account[0]);
        console.log(isWhitelisted);
        const cost = await contract.getCost(account[0]);
        const totalSupply = await contract.totalSupply();
        const maxSupply = await contract.maxSupply();
        setData({
          cost: String(cost),
          totalSupply: String(totalSupply),
          maxSupply: String(maxSupply),
        });
      } catch (error) {
        setError(error.message);
      }
    }
  }
  async function mint() {
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("hey");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(CRAdress, CockyRoaches.abi, signer);
      console.log("aa");
      try {
        let overrides = {
          from: accounts[0],
          value: data.cost,
        };
        console.log("hou");
        const transaction = await contract.mint(1, overrides);
        console.log("houa");
        await transaction.wait();
        console.log("houna");
        fetchData();
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    }
  }
  async function withdraw() {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(CRAdress, CockyRoaches.abi, signer);
      try {
        const transaction = await contract.withdraw();
        await transaction.wait();
      } catch (error) {
        setError(error.message);
      }
    }
  }
  async function reveal() {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(CRAdress, CockyRoaches.abi, signer);
      try {
        const transaction = await contract.reveal();
        await transaction.wait();
      } catch (error) {
        setError(error.message);
      }
    }
  }
  async function unReveal() {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(CRAdress, CockyRoaches.abi, signer);
      try {
        const transaction = await contract.unReveal();
        await transaction.wait();
      } catch (error) {
        setError(error.message);
      }
    }
  }
  async function transferOwnership() {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(CRAdress, CockyRoaches.abi, signer);
      try {
        const transaction = await contract.transferOwnership(owner);
        await transaction.wait();
      } catch (error) {
        setError(error.message);
      }
    }
  }
  return (
    <div className="App">
      {/* {account[0] === "0xf96affd919e3c213c58892ff84b8d34f1ceb148f" && ( */}
      <button onClick={withdraw}>withdraw</button>
      {/* )} */}
      {/* {account[0] === "0xf96affd919e3c213c58892ff84b8d34f1ceb148f" && ( */}
      <button onClick={reveal}>reveal</button>
      {/* )} */}
      {/* {account[0] === "0xf96affd919e3c213c58892ff84b8d34f1ceb148f" && ( */}
      <button onClick={unReveal}>unreveal</button>
      {/* )} */}
      {/* <input
        value={owner}
        onChange={(event) => {
          setOwner(event.target.value);
        }}
      />
      <button onClick={transferOwnership}>set Owner</button> */}
      <h1>Mint!</h1>
      <h2>
        minted : {data.totalSupply}/ {data.maxSupply}
      </h2>
      <h2>cout : {data.cost / 10 ** 18}eth (sans compter les frais de gas)</h2>
      <button onClick={mint}>Acheter un nft</button>
    </div>
  );
}

export default App;
